import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Injector,
  OnDestroy,
  OnInit,
  Provider,
  ViewChild,
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxIndexedDBService} from 'ngx-indexed-db';
import {RemarksComponent} from '../../order/order-detail/remarks/remarks.component';
import {HubCorporateService} from '../../shared/services/hub-corporate.service';
import {HelpersService} from '../../shared/services/helpers.service';
import {LocalStorageService} from '../../shared/services/local-storage.service';
import {NDCApiService} from '../../shared/services/ndc-api.service';
import { NgbDateParserFormatter, NgbDropdown, NgbModal, NgbModalRef, NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import {PaymentModuleService} from '../../shared/services/payment-module.service';
import {Dictionary} from '../../shared/types/dictionary';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TaxesInfo} from '../../shared/models/taxes-info.model';
import {Subject} from 'rxjs';
import {DataTransferService} from '../../shared/services/data-transfer.service';
import {AuthService} from '../../shared/services/auth.service';
import {
  DEFAULT_BOOKINGPAD_LOGO_PATH,
  FAKE_AIRLINES, GENDERS,
  ORDER_STATUS, ROLES,
} from '../../shared/constants';
import {OrderSearchService} from '../../shared/services/order-search.service';
import {PostRepriceOrder} from '../../shared/models/post-date-change-with-id.model';
import {map, take, takeUntil} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {NotificationService} from '../../shared/services/notification.service';
import {MetaService} from '../../shared/services/meta.service';
import {Process} from '../../shared/interfaces/show-process';
import {GtmService} from '../../shared/services/gtm.service';
import {SentryService} from '../../shared/services/sentry.service';
import {emptyFlightData} from '../../shared/models/empty-flight-data';
import {convertPhoneIntoString} from '../../shared/adapters/v1_2/adapters';
import {WindowRef} from '../../shared/services/window-ref.service';
import {PassiveSegmentService} from '../../shared/services/passive-segment.service';
import {PassengerUpdatesValidator} from "../../shared/validators/passenger-updates.validator";
import {OfferService} from "../../shared/services/offer.service";
import {OrderTab} from "../../shared/types/order";
import {OrderViewActionsState} from "../../shared/models/god-mode-actions";
import {PaymentSettings} from '../../shared/models/payment-settings';
import {AllowedPassengerUpdates} from "../../shared/interfaces/order-update";
import {ErrorAlert} from "../../shared/models/error-alert";
import {parsePhoneNumber} from "libphonenumber-js";
import {
  collapseAnimation,
  fadeInAnimation,
  fadeInOnEnterAnimation,
  fadeInUpAnimation,
  rotateAnimation
} from 'angular-animations';
import {RemarkDataToUpdate, RemarkTextToUpdate} from "../../shared/interfaces/remarks";
import {ConfirmDialogService} from "../../shared/services/confirm-dialog.service";
import {MinMaxDate} from "../../shared/interfaces/date";
import {AirlineNotification} from "../../shared/interfaces/airline-notification";
import {SortPassengersPipe} from "../../shared/pipes/sort-passengers.pipe";
import moment from "moment";
import { DateService } from '../../shared/services/date.service';
import { AirGatewayDateFormatter } from '../../shared/services/air-gateway-date-formatter.service';


@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss'],
  animations: [
    fadeInOnEnterAnimation({ duration: 500, delay: 200 }),
    fadeInUpAnimation(),
    fadeInAnimation(),
    rotateAnimation({duration: 500 }),
    collapseAnimation()
  ],
  providers: [
    SortPassengersPipe,
    <Provider>{
      provide: NgbDateParserFormatter,
      useClass: AirGatewayDateFormatter,
    }
  ]
})
export class OrderDetailComponent implements OnInit, OnDestroy {
  frontendSelectedDependency = environment.default;
  order;
  owner;
  completed = null;
  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  orderCancelResponseWarning = [];
  itinReshopRefundResponseWarning = [];
  taxesInfo: TaxesInfo[];
  scale = 0.6;
  orderCreateError: ErrorAlert = new ErrorAlert();
  orderCancelResponseSuccess = false;
  showOrderRetrieveLoader = false;
  orderRetrieveError: ErrorAlert = new ErrorAlert();
  orderRetrieveWarnings = [];
  orderFromItinReshopReprice;
  orderReshopRepriceError: ErrorAlert = new ErrorAlert();
  orderReshopRefundError: ErrorAlert = new ErrorAlert();

  isCancelOrderButtonDisabled = false;
  showOrderCancelLoader = false;
  showItinReshopRefundLoader = false;
  currentOrderUpdateAction: string;

  orderChangeWarning = [];
  currentNgbDate;
  currentDate: Date = new Date();

  selectedSegmentsMapCopy = {};
  selectedSegmentsCopy = [];
  onTicketed;
  id = '';
  orderWarnings = [];
  allWarningsAreShown: boolean;
  isWarningsReadBtnNeeded: boolean;
  showWarnings = true;

  showRepriceIssueLoader = false;

  flightDataStatus = {
    isDataWrong: false,
    errorTitle: 'Unable to load Flights Data.'
  };
  passengersDataStatus = {
    isDataWrong: false,
    errorTitle: 'Unable to load Passengers Data.'
  };
  orderConfirmProcess: Process = {
    isProcess: false,
    processTitle: 'Confirming disrupted order...'
  };
  orderIssueProcess: Process = {
    isProcess: false,
    processTitle: 'Order issue...'
  };
  orderCancelProcess: Process = {
    isProcess: false,
    processTitle: 'Canceling...'
  };
  orderVoidProcess: Process = {
    isProcess: false,
    processTitle: 'Voiding...'
  };
  orderUpdateProcess: Process = {
    isProcess: false,
    processTitle: 'Updating...'
  };

  tabsWarnIconVisibility: any = {};

  @ViewChild('paymentFormModal', {static: true}) paymentFormModal: ElementRef;
  @ViewChild('cardTypeSelect') cardTypeSelect: ElementRef;
  @ViewChild(RemarksComponent)
  remarksComponent: RemarksComponent;
  @ViewChild(NgbTabset, {static: false}) tabset;
  @ViewChild('warnings') warnings: ElementRef;
  @ViewChild('orderVoidInfoModal', {static: true}) orderVoidInfoModal: ElementRef;
  @ViewChild('orderCancelModal', {static: true}) orderCancelModal: ElementRef;
  @ViewChild('reshopOfferContent', {static: true}) orderReshopModal: ElementRef;

  validateTriggered = false;

  paymentObject: any = null;
  paymentSetCallback: any;
  cbServiceArg = null;
  penalty: any;
  refundPrice: any;
  originalOrderPrice: any;

  today;
  files = null;

  originDestinationsData = [];

  readonly allOriginsAPI = 'https://api.allorigins.win/get?url=';
  isPDFExtract = false;

  paymentSettings: PaymentSettings = new PaymentSettings();
  paymentStep = 0;
  fromSubagencies: string;
  pdfShowOptions = {
    tableInfo: {isShow: true, label: 'Table Info'},
    paymentInfo: {isShow: true, label: 'Payment Info'},
    flightDetails: {isShow: true, label: 'Flight Details'},
    passengers: {isShow: true, label: 'Passengers'},
    details: {isShow: true, label: 'Details', group: 'passengers'},
    seats: {isShow: true, label: 'Seats', group: 'passengers'},
    services: {isShow: true, label: 'Services', group: 'passengers'},
    ticketInformation: {isShow: true, label: 'Tickets Information', group: 'passengers'},
    disclosures: {isShow: true, label: 'Disclosures', group: 'passengers'},
    fareRules: {isShow: true, label: 'Fare Rules', group: 'passengers'},
    comments: {isShow: true, label: 'Comments'},
  };
  isAllowPdf = true;
  showOrderPdfLoader = false;
  readonly ORDER_STATUS = ORDER_STATUS;
  fareRules: any[] = [];
  protected selectedFare: any;
  fareNames: any;
  mandatoryRepriceBeforePayment = false;
  latestPrice;

  viewOpts: any = {};
  seatsToRemove: any = [];
  private isRemoveFreeSeats = false;
  private isRemoveFreeService = false;

  orderChangerSeatSegmentID: any = {};
  orderChangerServiceSegmentID: any = {};
  serviceIdToRemove = '';
  fareRulesCapacity;
  isShowRefundWarning = false;
  isShowPendingPaymentsWarning = false;
  orderCancelError: ErrorAlert = new ErrorAlert();
  isRemarksIconVisible: boolean;
  agencyRemarkTemplates = [];
  corporateRemarkTemplates = [];
  corporateRemarksData: RemarkDataToUpdate | RemarkTextToUpdate;
  isRemarksFilled: boolean;
  isRemarksTemplateSelected: boolean;
  providerRemarks = [];
  orderInfo = {};
  corporate: any;
  corporateID: string;
  agencyImage: string;
  defaultBookingPadLogo = DEFAULT_BOOKINGPAD_LOGO_PATH;
  storeData;
  orderCancelType: '' | 'void' | 'refund' = '';
  orderCancelModalButtonNames = {
    cancel: {isShow: false, label: 'Order Cancel'},
    void: {isShow: false, label: 'Order Void'},
    cancelAndRefund: {isShow: false, label: 'Order Refund'},
  };
  orderCancelModalTitle = 'Order Cancel';
  paymentMethodsExists = true;
  dbService: NgxIndexedDBService;
  originalSabrePassiveSegment: string;
  anonymizedSabrePassiveSegment: string;
  originalAmadeusPassiveSegment: string;
  anonymizedAmadeusPassiveSegment: string;
  travelportPassiveSegment: string;
  passengerForm: FormGroup;
  passengerFormData = {};
  namePattern = /^[a-zA-Z\-\s]+$/;
  telInputObjects = {};
  passengerDataToUpdate;
  passengersDropdownDisabled = false;
  allowedPassengerUpdates: AllowedPassengerUpdates;
  allowedUpdatesPerPassengerType = {};
  availablePassengersToUpdate = [];
  selectedPassenger;
  countries = Dictionary.Countries;
  otherDocumentTypes = Dictionary.DocumentTypes;
  dictionaryAirlineDesignators = Dictionary.AirlineDesignators;
  fakeAirlines = FAKE_AIRLINES;
  titles: string[];
  genders = GENDERS;
  orderTab = OrderTab;
  selectedTab: OrderTab = OrderTab.OrderDetails;
  ROLES = ROLES;
  minMaxDateOfBirth: { [key: string]: MinMaxDate } = {};
  minMaxDocumentExpirationDate: MinMaxDate = new MinMaxDate();
  airlineNotification: AirlineNotification;
  airlineNotificationModalRef: NgbModalRef;
  startedFlightsFlags: boolean[] = [];

  //God mode
  orderViewActions: OrderViewActionsState = new OrderViewActionsState();
  isFirstStateSaved = false;
  isGodModeEnabled = false;

  // legacy PDF
  imgLen = 0;
  counter = 0;
  pdf = null;

  addresses = {
    home: false,
    destination: false,
  };

  isOrderTableOpened = false;
  isPaymentOpened = true;
  isPassengersOpened = true;
  isAncillariesTableBySegmentHidden = {};
  isPassengersDataOpened = {details: false, birthdate: false, contact: false, address: false, documents: false, ffn: false};

  isAtBottom = false;

  buttonStates = {
    selectSeatsButton: { isDisabled: false },
    selectServicesButton: { isDisabled: false },
    orderRebookButton: { isDisabled: false },
    orderSplitButton: { isDisabled: false },
    orderCancelButton: { isDisabled: false }
  };

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    this.determineScrollPosition();
  }

  constructor(private route: ActivatedRoute,
              private router: Router,
              public helpers: HelpersService,
              private meta: MetaService,
              public dataTransferService: DataTransferService,
              private fb: FormBuilder,
              public service: NDCApiService,
              private authService: AuthService,
              public ls: LocalStorageService,
              protected _notificationSvc: NotificationService,
              public paymentService: PaymentModuleService,
              private cdr: ChangeDetectorRef,
              private modalService: NgbModal,
              private orderSearch: OrderSearchService,
              private gtmService: GtmService,
              private sentryService: SentryService,
              public hubCorporateService: HubCorporateService,
              private passiveSegmentService: PassiveSegmentService,
              private winRef: WindowRef,
              private injector: Injector,
              private offerService: OfferService,
              private dateService: DateService,
              public confirmDialogService: ConfirmDialogService,
              private sortPassengersPipe: SortPassengersPipe,
  ) {
    if (!this.helpers.isPrivateMode) {
      this.dbService = <NgxIndexedDBService>this.injector.get(NgxIndexedDBService);
    }
  }

  ngOnInit() {
    this.today = (new Date()).getTime();
    this.checkDefaultFEDependency();

    this.id = this.route.snapshot.paramMap.get('id');
    this.completed = this.route.snapshot.queryParamMap.get('completed');
    this.fromSubagencies = this.route.snapshot.queryParamMap.get('from_subagencies');

    let params: any = {};
    if (this.fromSubagencies === 'true') {
      params.from_subagencies = 'true';
    }
    this.router.navigate([], {queryParams: params});
    this.loadOrder(!!this.completed);

    this.currentNgbDate = {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate(),
    };
    this.redirectToNewUrl();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  @HostListener('window:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.shiftKey && (event.ctrlKey || event.altKey) && event.code === 'KeyF' && this.paymentService.form && this.paymentSettings.card) {
      this.paymentService.fillPaymentData(this.service.owner, this.paymentService.form);
      this.cardTypeSelect?.nativeElement?.focus();
    }
  }

  private buildPaymentForm() {
    let defaultMethod = 'none';
    if (this.paymentSettings.agencyCash) {
      defaultMethod = 'agencyCash';
    } else if (this.paymentSettings.agencyCard) {
      defaultMethod = 'agencyCard';
    } else if (this.paymentSettings.card) {
      defaultMethod = 'card';
    }

    if (this.paymentObject && this.paymentObject.method) {
      defaultMethod = this.paymentObject.method;
    }

    const setParams: any = {};

    if (this.paymentObject) {
      Object.keys(this.paymentObject).forEach(key => {
        setParams[key] = this.paymentObject[key];
      });
    }
    this.paymentService.buildForm(defaultMethod, setParams);
  }

  // loadOrder
  loadOrder(resync = false, event = '') {
    this.orderChangerSeatSegmentID = {};
    this.orderChangerServiceSegmentID = {
      segmentId: null
    };

    let body = {
      id: this.id
    };
    this.order = null;
    this.showOrderRetrieveLoader = true;
    this.orderCreateError = new ErrorAlert();

    this.service.sendOrderRetrieve(body, resync)
      .then((res) => {
        if (!res.flights) {
          this.flightDataStatus.isDataWrong = true;
          res.flights = emptyFlightData;
        }
        if (!res.passengers) {
          this.passengersDataStatus.isDataWrong = true;
          res.passengers = [];
        }

        this.order = res;

        this.markDeprecatedFlights();

        this.allowedPassengerUpdates = this.order.allowedPassengerUpdates || {};
        this.availablePassengersToUpdate = this.sortPassengersPipe.transform(this.order.passengers);
        this.selectedPassenger = this.availablePassengersToUpdate?.find(passenger => passenger.passengerType === 'ADT') || this.availablePassengersToUpdate[0];
        this.findAllowedUpdatesPerPassengerType();
        this.buildPassengerForm();
        this.addControlForEachPassenger();
        this.getPassengersForUpdate();
        this.passengerFormData = this.passengerForm.value;
        this.minMaxDateOfBirth = this.helpers.prepareBirthdateValidation(this.order);
        this.titles = this.helpers.getTitles(this.order.availableTitles);
        this.minMaxDocumentExpirationDate.minDate = this.helpers.formatDateToNgbDateStruct(this.order.flights[0].departure.date);

        this.owner = this.service.owner = this.sentryService.owner = this.order.bookingReference?.airlineID;
        this.helpers.lastOrderPnr = this.order.pnr;
        if ((this.completed || this.onTicketed) && this.order.status === ORDER_STATUS.PENDING && this.helpers.isOrderTicketed) {
          this.orderCreateError.message = 'Tickets have not been issued';
          this.completed = null;
          this.onTicketed = null;
        }

        if (this.isPendingOrder()) {
          this.orderCancelModalButtonNames.cancel.isShow = true;
          if (this.order.mandatoryRequests?.orderReshopReprice) {
            this.mandatoryRepriceBeforePayment = true;
          }
          this.latestPrice = {
            value: this.order.price.consumer.total,
            currency: this.order.price.consumer.currency
          };
        } else {
          this.orderCancelModalButtonNames.cancel.isShow = false;
        }

        this.fareNames = !this.flightDataStatus.isDataWrong ? this.helpers.prepareFareInfo(this.order, true, true) : [];

        this.showOrderRetrieveLoader = false;

        this.orderRetrieveWarnings = this.helpers.getRefactoredWarnings(res.warnings);
        this.providerRemarks = this.helpers.getRefactoredWarnings(res.providerRemarks);
        this.isShowPendingPaymentsWarning = event !== 'refreshOrderButtonPress' ? this.isPendingPaymentsWarningNeeded() : false;

        this.prepareOrderWarnings();
        this.getWarningsHeight();

        this.setOrderInfo();

        this.startedFlightsFlags = this.order.flights.map(flight => {
          const departureDateAndTime = flight.departure.date + ' ' + flight.departure.time;
          return this.isStartedOrder() && moment().isAfter(departureDateAndTime);
        });
        this.order.services = this.getServices();
        this.order.seats = this.order.seats || [];
        this.taxesInfo = this.order.price ? this.getBreakdown(this.order.price.consumer) : [];
        this.setInfantReferenceFullNameForDesignatedAdults();
        this.removeEmptyTitleFromPassengers(this.order.passengers);
        this.updateAllowedPaymentMethods();
        this.paymentSettings = this.order.allowedPaymentMethods;

        this.originDestinationsData = this.helpers.getOriginDestinations(this.order.flights);
        this.buildPaymentForm();
        this.fareRules = this.offerService.prepareFareRules(this.order.flights);
        this.files = this.order.files;
        this.updateIssuingPendings();
        this.setViewOpts();
        this.isRemarksIconVisible = !this.order.remarks.result;
        this.isRemarksFilled = !this.isRemarksIconVisible;
        this.agencyRemarkTemplates = this.order.remarks.templates || [];
        this.corporateID = this.order?.account?.id;
        this.getCorporate(this.corporateID);
        this.onWarnIconCheck();

        if (event && event !== 'refreshOrderButtonPress') {
          this.meta.setMetadata('OrderChange', this.order);
        }
        this.paymentMethodsExists = Object.values(this.order.allowedPaymentMethods).some(x => x !== false);
        this.checkGodMode();
      })
      .catch((response) => {
        this.showOrderRetrieveLoader = false;
        this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, body, response.status, response);
        this.orderRetrieveError = this.helpers.getError(response);
        if (this.helpers.isCriticalError(response)) {
          throw response;
        }
      });
  }

  markDeprecatedFlights() {
    this.order.flights.forEach((flight, index, flightsArray) => {
      const { departure, arrival, segments } = flight;

      const hasSegmentType = segments.some(segment => segment.detail.segmentType);

      const duplicateFlight = flightsArray.find((otherFlight, otherIndex) => {
        if (index === otherIndex) { return false; } // Skip the same flight
        return (
          otherFlight.departure.airportCode === departure.airportCode &&
          otherFlight.arrival.airportCode === arrival.airportCode
        );
      });

      if (duplicateFlight) {
        const otherHasSegmentType = duplicateFlight.segments.some(segment => segment.detail.segmentType);

        if (hasSegmentType !== otherHasSegmentType) {
          flight.deprecated = !hasSegmentType; // Mark the one without `segmentType` as deprecated
        }
      }
    });
  }

  getServices() {
    if (!this.order.services) {
      return [];
    }
    const segmentIds  = [];
    const uniqServiceCodes = [];
    this.order.flights.map(flight => flight.segments.map(segment => segmentIds.push(segment.segmentID)));
    const sortedServices = this.order.services
      .sort((a: any, b: any) => segmentIds.indexOf(a.segmentReferences) - segmentIds.indexOf(b.segmentReferences))
      .map(service => {
        if (service.price.consumer.total) {
          const bundledService = this.order.services.find(s => s.serviceID.length && s.serviceID === service.bundledWith);
          if (bundledService) {
            service.price.consumer.total = 0;
            service.priceHidden = true;
            return service;
          }
          const serviceCode = service.serviceID + service.travelerReferences + service.segmentReferencesIncluded?.join('') || '';
          if (uniqServiceCodes.includes(serviceCode)) {
            service.price.consumer.total = 0;
            service.priceHidden = true;
          } else {
            uniqServiceCodes.push(serviceCode);
          }
        }
        return service;
      });
    return sortedServices;
  }

  getBreakdown(consumer: any) {
    if (consumer.tax.breakdown && consumer.tax.breakdown.length) {
      return consumer.tax.breakdown;
    } else if (consumer.breakdown && consumer.breakdown[0]?.tax?.breakdown && consumer.breakdown[0].tax.breakdown.length) {
      let breakdown = [];
      consumer.breakdown.map(bd => {
        bd.tax.breakdown?.map(b => {
          breakdown.push(b);
        });
      });
      return breakdown;
    } else {
      return [];
    }
  }

  prepareOrderWarnings() {
    const warningsInfo = this.ls.orderWarnings;

    if (warningsInfo && warningsInfo !== "null") {
      try {
        const parsedWarningsInfo = JSON.parse(warningsInfo);

        if (parsedWarningsInfo.id === this.order?.id) {
          this.orderWarnings = this.helpers.getRefactoredWarnings(parsedWarningsInfo.warnings);
        }
      } catch (error) {
        console.error('Error parsing JSON:', error);
      } finally {
        this.ls.orderWarnings = null;
      }
    }
  }

  onChangePassengersData(target, event) {
    const passengersIsShowArray = [];
    const isShowArray = [];
    let keys = Object.keys(this.pdfShowOptions);

    if (target === 'passengers') {
      keys.map(key => {
        if (this.pdfShowOptions[key].group) {
          this.pdfShowOptions[key].isShow = event.target.checked;
        }
      });
    }

    keys.map(key => {
      if (this.pdfShowOptions[key].group && event.target.checked) {
        this.pdfShowOptions['passengers'].isShow = true;
      }
    });

    keys.map(key => {
      if (this.pdfShowOptions[key].group) {
        passengersIsShowArray.push(this.pdfShowOptions[key].isShow);
      }
    });

    if (!passengersIsShowArray.includes(true)) {
      this.pdfShowOptions['passengers'].isShow = false;
    }

    keys.map(key => {
      isShowArray.push(this.pdfShowOptions[key].isShow);
    });

    this.isAllowPdf = isShowArray.includes(true);
  }

  updateOrderRemarks(remark) {
    this.order.remarks = remark;
    if (this.corporateRemarksData?.result) {
      this.isRemarksFilled = true;
    }

    this.isRemarksIconVisible = !remark.result;
  }

  updateCorporateRemarks(remark) {
    this.corporateRemarksData = remark;
    if (this.order.remarks.result) {
      this.order.remarks = remark;
      this.isRemarksFilled = true;
    }

    this.isRemarksIconVisible = !remark.result;
  }

  editBothRemarks() {
    this.isRemarksFilled = false;
    this.isRemarksIconVisible = true;
    this.order.remarks.result = '';
    this.corporateRemarksData = null;
    delete this.order.remarks.data?.variables;
  }

  updateOrderComments(comment) {
    this.order.comments = comment;
  }

  openVoidingInfoModalOrCallOrderCancel(type: '' | 'void' | 'refund') {
    if (type === 'void' && this.orderCancelModalButtonNames.cancelAndRefund.isShow && !this.ls.isVoidingInfoModalSkipped) {
      this.open(this.orderVoidInfoModal, 'md');
    } else {
      this.actionCancelOrder(type);
    }
  }

  actionCancelOrder(type: '' | 'void' | 'refund') {
    this.orderCancelType = type;
    this.orderCancelProcess.isProcess = true;
    let body = {
      id: this.id,
      type: this.orderCancelType
    };
    this.showOrderCancelLoader = true;
    this.orderCancelResponseWarning = [];

    this.service.sendOrderCancel(body)
      .then((res) => {
        this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, body, 200, res);
        this.gtmService.addEvent('OrderCancel');
        this.meta.setMetadata('OrderCancel', this.order);
        this.showOrderCancelLoader = false;

        this.orderCancelResponseWarning = this.helpers.getWarningsFromResponse(res);

        this.orderCancelModalButtonNames.cancelAndRefund.isShow = this.orderCancelModalButtonNames.void.isShow =
          this.orderCancelModalButtonNames.cancel.isShow = false;
        this.orderCancelResponseSuccess = true;
        this.orderCancelProcess.isProcess = false;
        this.orderCancelError = new ErrorAlert();
        this.loadOrder(true);
        this.completed = null;
        this.orderWarnings = null;
      })
      .catch((res) => {
        this.sentryService.notify(res);
        this.orderCancelError = this.helpers.getError(res);
        this.orderCancelResponseSuccess = false;
        this.showOrderCancelLoader = false;
        this.orderCancelProcess.isProcess = false;
        this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, body, res.status, res);
        if (this.helpers.isCriticalError(res)) {
          throw res;
        }
      });
  }

  actionItinReshopRefund(refundType: string) {
    this.isCancelOrderButtonDisabled = true;
    let body = {
      id: this.id,
      type: refundType
    };

    this.showItinReshopRefundLoader = true;
    this.itinReshopRefundResponseWarning = [];

    this.service.sendOrderReshopRefund(body)
      .then((res) => {
        switch (res.type as string) {
          case 'void':
            this.orderCancelModalButtonNames.void.isShow = true;
            this.orderCancelModalButtonNames.void.label = 'Proceed';
            this.orderCancelModalTitle = 'Order Void';
            break;
          case 'refund':
            this.orderCancelModalButtonNames.cancelAndRefund.isShow = true;
            this.orderCancelModalButtonNames.cancelAndRefund.label = 'Proceed';
            this.orderCancelModalTitle = 'Order Refund';
            break;
          case 'unknown':
            this.orderCancelModalButtonNames.void.isShow = true;
            this.orderCancelModalButtonNames.void.label = 'Order Void';
            this.orderCancelModalButtonNames.cancelAndRefund.isShow = true;
            this.orderCancelModalButtonNames.cancelAndRefund.label = 'Order Refund';
            this.orderCancelModalTitle = 'Order Cancel';
            break;
        }

        this.closeNotificationModal();
        this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, body, 200, res);
        this.showItinReshopRefundLoader = false;
        this.isCancelOrderButtonDisabled = false;
        this.itinReshopRefundResponseWarning = this.helpers.getWarningsFromResponse(res);

        if (res.type !== 'void') {
          this.fareRulesCapacity = this.offerService.getFareRules(res);
          this.penalty = res.penalty;
          this.refundPrice = res.refund;
          this.originalOrderPrice = res.originalOrder;
          this.isShowRefundWarning =
            (this.fareRulesCapacity?.length > 0 && this.fareRulesCapacity[0].cancel === 'Not Allowed') &&
            this.originalOrderPrice?.consumer?.total !== (this.penalty?.consumer?.total ?? 0) + (this.refundPrice?.consumer?.total ?? 0) ||
            !this.fareRulesCapacity && this.originalOrderPrice?.consumer?.total !== (this.penalty?.consumer?.total ?? 0) + (this.refundPrice?.consumer?.total ?? 0);
        }
      })
      .catch((res) => {
        this.sentryService.notify(res);
        this.showItinReshopRefundLoader = false;
        this.orderReshopRefundError = this.helpers.getError(res);
        this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, body, res.status, res);
        if (this.helpers.isCriticalError(res)) {
          throw res;
        }
      });
  }

  onAirlineNotificationModalButtonClick(button: 'refund' | 'rebook' | 'acceptChange') {
    if (button === 'refund') {
      this.open(this.orderCancelModal);
      this.actionItinReshopRefund('refund');
    } else if (button === 'rebook') {
      this.openReshopOfferModal(this.orderReshopModal, 'offers');
    } else if (button === 'acceptChange') {
      this.actionOrderConfirm();
    }
  }

  onNotificationChange(res) {
    if (res) {
      this.order = res;
      this.setViewOpts();
    }
  }

  openFirstRequiredNotification(content) {
    const airlineNotification = [...this.order.airlineNotifications].reverse()
      .find((notification: AirlineNotification) => notification.actionRequired?.length);
    this.openNotificationModal(content, airlineNotification);
  }

  openNotificationModal(content, airlineNotification: AirlineNotification) {
    this.airlineNotification = airlineNotification;
    this.airlineNotificationModalRef = this.modalService.open(content, {size: 'lg', windowClass: 'modal-pnr'});
  }

  closeNotificationModal() {
    if (this.airlineNotificationModalRef) {
      this.airlineNotificationModalRef.close();
    }
  }

  open(content, size: any = 'lg', windowClass = 'modal-pnr') {
    this.modalService.open(content, {
      size: size,
      windowClass: windowClass
    }).result.then((result) => {
    }, (reason) => {
    });
  }

  actionSearchAgain() {
    this.addPassengersInfo();
    let travelersPrepared = {
      adt: 0,
      yad: 0,
      chd: 0,
      inf: 0
    };

    if (this.order.passengers) {
      [...this.order.passengers].forEach(passenger => {
        travelersPrepared[passenger.passengerType.toLowerCase()] += 1;
      });
    }

    let originDestinations = [...this.order.flights];

    let searchType;

    if ((originDestinations.length === 2 && (
      (originDestinations[0].departure.airportCode !== originDestinations[1].arrival.airportCode) ||
      (originDestinations[0].arrival.airportCode !== originDestinations[1].departure.airportCode)
    )) || (originDestinations.length > 2 && this.order.bookingType !== 'MC')) {
      searchType = 'MC';
    } else {
      searchType = this.order.bookingType;
    }

    const buildParams = {
      searchType,
      travelers: travelersPrepared,
      cabin: '7',
      fare: '1',
      originDestinations: originDestinations,
      corporate_id: this.order?.account?.id,
      owner: this.owner,
      searchAgain: true
    };

    this.orderSearch.buildUrl(buildParams);
  }

  addPassengersInfo() {
    if (!this.helpers.isPrivateMode) {
      const routes = [];
      this.order.flights.map(flight => {
        const route = flight.departure.airportCode + ' → ' + flight.arrival.airportCode;
        routes.push(route);
      });
      const storeData = {
        passengers: this.order.passengers,
        routeInfo: routes.join(', '),
        loyaltyProgramAccount: this.order.loyaltyProgramAccount,
        otherServiceInformation: this.order.otherServiceInformation,
      };
      this.dbService.add('passengers_info', storeData).subscribe();
    }
  }

  removeService() {
    if (!this.paymentObject && this.order.status !== ORDER_STATUS.PENDING && !this.isRemoveFreeService) {
      return;
    }

    this.orderChangerServiceSegmentID = {
      segmentId: this.serviceIdToRemove,
      showLoader: true
    };

    let type = 'service';
    let services = [
      {
        type: type,
        serviceID: this.cbServiceArg.serviceID,
        segmentReference: this.cbServiceArg.segmentReferences,
        travelerReference: this.cbServiceArg.travelerReferences,
        action: 'Cancel',
      }
    ];

    let body = {
      id: this.id,
      payment: this.paymentObject,
      services: services,
      passengers: this.order.passengers,
    };

    switch (this.ls.appVersion) {
      case 'v1.2':
        if (body.payment?.phone && typeof body.payment?.phone !== 'string') {
          body.payment.phone = convertPhoneIntoString(body.payment.phone);
        }
        break;
    }

    body.passengers = body.passengers.map((item) => {
      delete item.document;
      delete item.documents;
      return item;
    });
    if (this.order.status === ORDER_STATUS.PENDING) {
      delete body.payment;
    }


    this.service.sendOrderChange(body)
      .then((res: any) => {
        this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, body, 200, res);
        this.gtmService.addEvent('OrderChange.RemoveServices');
        this.orderChangeWarning = this.helpers.getWarningsFromResponse(res);
        this.orderChangerServiceSegmentID = {
          segmentId: this.serviceIdToRemove,
          showLoader: false
        };
        this._notificationSvc.success('Service removed', 'Service item has been successfully ordered');
        this.saveWarnings(res);
        setTimeout(() => {
          this.loadOrder(true);
        }, 2000);

      })
      .catch((res) => {
        this.orderChangerServiceSegmentID = {
          segmentId: this.serviceIdToRemove,
          showLoader: false
        };
        this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, body, res.status, res);
        if (this.helpers.isCriticalError(res)) {
          throw res;
        }
      });
    /*})*/
  }

  removeSeats(segmentId: string = '') {
    if (!this.paymentObject && this.order.status !== ORDER_STATUS.PENDING && !this.isRemoveFreeSeats) {
      return;
    }
    this.isRemoveFreeSeats = false;

    if (!segmentId && this.seatsToRemove.length) {
      segmentId = this.seatsToRemove[0].segment;
    }
    this.orderChangerSeatSegmentID = {
      ...this.selectedSegmentsMapCopy,
      showLoader: true
    };

    let services = [];
    this.seatsToRemove.map(seat => {
      services.push({
        type: 'seat',
        serviceID: seat.listKey,
        segmentReference: seat.segment,
        travelerReference: seat.refs,
        action: 'Cancel',
      });
    });

    let body = {
      id: this.id,
      payment: this.paymentObject,
      services: services,
      passengers: this.order.passengers,
    };

    switch (this.ls.appVersion) {
      case 'v1.2':
        if (body.payment?.phone && typeof body.payment?.phone !== 'string') {
          body.payment.phone = convertPhoneIntoString(body.payment.phone);
        }
        break;
    }

    body.passengers = body.passengers.map((item) => {
      delete item.document;
      delete item.documents;
      return item;
    });
    if (this.order.status === ORDER_STATUS.PENDING) {
      delete body.payment;
    }


    this.service.sendOrderChange(body)
      .then((res: any) => {
        this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, body, 200, res);
        this.gtmService.addEvent('OrderChange.RemoveSeats');
        this.orderChangeWarning = this.helpers.getWarningsFromResponse(res);

        this.orderChangerSeatSegmentID = {
          showLoader: false
        };
        this._notificationSvc.success('Seat removed', `Seat item has been successfully removed`);

        this.seatsToRemove.map(rmSeat => {
          this.order.seats = this.order.seats.filter(seat => seat.listKey !== rmSeat.listKey);
        });

        this.saveWarnings(res);
        setTimeout(() => {
          this.loadOrder(true, 'OrderChange');
        }, 2000);

      })
      .catch((res) => {
        this.orderChangerSeatSegmentID = {
          showLoader: false
        };
        this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, body, res.status, res);
        if (this.helpers.isCriticalError(res)) {
          throw res;
        }
      });
  }


  actionAirDocIssue() {
    if (!this.paymentObject) {
      return;
    }
    this.orderIssueProcess.isProcess = true;

    let id = this.id;
    let body = {
      id,
      payment: {...this.paymentObject},
    };
    switch (this.ls.appVersion) {
      case 'v1.2':
        if (body.payment.phone) {
          body.payment.phone = convertPhoneIntoString(body.payment.phone);
        }
        break;
    }

    this.service.sendAirDocIssue(body)
      .then((response) => {
        if (this.isPendingOrder() && this.order?.status === response?.status) {
          this.helpers.isOrderTicketed = true;
        }
        this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, body, 200, response);
        this.gtmService.addEvent('OrderIssue');
        this.meta.setMetadata('OrderIssue', response);
        this.onTicketed = true;
        this.orderIssueProcess.isProcess = false;
        this.saveWarnings(response);
        this.paymentStep = 0;

        if (this.fakeAirlines.includes(this.owner)) {
          // this.tabset.activeId = this.tabset.tabs.first.id;
          this.selectedTab = this.orderTab.OrderDetails;
          Object.keys(this.order).forEach(key => {
            if (response[key]) {
              this.order[key] = response[key];
            }
          });
          this.setViewOpts();
        } else {
          this.loadOrder(true);
        }

      })
      .catch((res) => {
        this.paymentStep = 0;
        this.orderIssueProcess.isProcess = false;
        this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, body, res.status, res);
        if (this.helpers.isCriticalError(res)) {
          throw res;
        }
      });
  }

  savePayment() {
    this.paymentObject = this.paymentService.getPaymentData();

    if (this.paymentSetCallback) {
      this.paymentSetCallback();
    }

    return true;
  }

  validate() {
    this.validateTriggered = true;
    return this.paymentService.form.valid;
  }

  ensurePaymentIsSet(callback) {
    this.paymentSetCallback = callback;
    this.open(this.paymentFormModal, 'lg', 'payment-modal');
  }

  isServiceListAllowed() {
    return (
      (this.order.allowedRequests.pending.ServiceList && this.isPendingOrder()) ||
      (this.order.allowedRequests.ticketed.ServiceList && (this.isTicketedOrder() || this.isIssuedOrder())) ||
      (this.order.allowedRequests.started.ServiceList && this.isStartedOrder())
    );
    // && !this.isBlockingDisruption();
  }

  isSeatAvailabilityAllowed() {
    return (
      (this.order.allowedRequests.pending.SeatAvailability && this.isPendingOrder()) ||
      (this.order.allowedRequests.ticketed.SeatAvailability && (this.isTicketedOrder() || this.isIssuedOrder())) ||
      (this.order.allowedRequests.started.SeatAvailability && this.isStartedOrder())
    );
    // && !this.isBlockingDisruption();
  }

  isChangeItineraryAllowed() {
    return ((this.order.allowedRequests.ticketed.OrderReshop && (this.isTicketedOrder() || this.isIssuedOrder())) ||
      (this.order.allowedRequests.started.OrderReshop && this.isStartedOrder()) ||
      (this.order.allowedRequests.pending.OrderReshop && this.isPendingOrder())
    );
  }

  isAirdocIssueAllowed() {
    return this.order.allowedRequests.pending.TicketIssue && this.isPendingOrder();
  }

  isCancelOrderAllowed() {
    return (this.isPendingOrder() && this.order.allowedRequests.pending.OrderCancel) ||
      (this.isStartedOrder() && this.order.allowedRequests.started.OrderCancel) ||
      (this.isTicketedOrder() || this.isIssuedOrder()) && (this.order.allowedRequests.ticketed.OrderReshopRefund);
  }

  isActionRequired() {
    return !!this.order?.airlineNotifications?.length && this.order.airlineNotifications.some((notification: AirlineNotification) => notification.actionRequired?.length);
  }

  isRepriceAllowed() {
    return this.isPendingOrder() && this.order.allowedRequests.pending.OrderReprice;
  }

  isPendingPaymentsExist() {
    return this.isTicketedOrder() && this.order.issuingPendings?.length;
  }

  isOrderSplitAllowed() {
    const adultPassengers = this.order.passengers?.filter(passenger => passenger.passengerType !== 'INF');
    return adultPassengers?.length > 1 && ((this.order.status === this.ORDER_STATUS.PENDING && this.order.allowedRequests.pending.OrderSplit) ||
      ([this.ORDER_STATUS.TICKETED, this.ORDER_STATUS.STARTED].indexOf(this.order.status) > -1 &&
        this.order.allowedRequests.pending.OrderSplit));
  }

  isCancelledOrder() {
    return this.order.status === this.ORDER_STATUS.CANCELED;
  }

  isPendingOrder() {
    return this.order ? this.order.status === this.ORDER_STATUS.PENDING : false;
  }

  isStartedOrder() {
    return this.order ? this.order.status === this.ORDER_STATUS.STARTED : false;
  }

  isTicketedOrder() {
    return this.order ? this.order.status === this.ORDER_STATUS.TICKETED : false;
  }

  isIssuedOrder() {
    return this.order?.status === this.ORDER_STATUS.ISSUING;
  }

  getDepartureRemaining(order: any) {
    let departure = new Date(order.flights[0].departure.date);
    let dayDiff = Math.floor((departure.getTime() - this.today) / 1000 / 60 / 60 / 24) + 1;
    return dayDiff;
  }

  openReshopOfferModal(content, type: string) {
    this.dataTransferService.changeDateOrderData = {
      targetOrder: this.order,
      selectedDate: this.currentNgbDate,
      originDestinations: this.originDestinationsData,
      type: type,
    };
    this.modalService.open(content, {ariaLabelledBy: 'reshopOffer', size: 'xl'});
  }

  openOrderSplitModal(content) {
    this.modalService.open(content, {ariaLabelledBy: 'orderSplit', size: 'lg'});
  }

  onRepriceWaiting() {
    this.dataTransferService.changeDateOrderData = {
      targetOrder: this.order,
      selectedDate: this.currentNgbDate,
      originDestinations: this.originDestinationsData,
      type: 'order',
    };
    if (this.dataTransferService.changeDateOrderData.type === 'order') {
      this.sendRepriceOrder();
    }
    if (!!this.remarksComponent) {
      this.remarksComponent.isButtonVisible = {
        edit: false,
        cancel: false,
        save: false,
      };
    }
  }

  protected sendRepriceOrder() {
    this.showRepriceIssueLoader = true;
    const body: PostRepriceOrder = {
      id: this.order.id,
    };

    this.service.sendOrderReshopReprice(body)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(response => {
        this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, body, 200, response);
        this.orderReshopRepriceError = new ErrorAlert();
        this.viewOpts.repriceButton = false;
        this.orderFromItinReshopReprice = response.body;
        let orderReshopPriceObj = this.orderFromItinReshopReprice.price;
        let priceObj = {...this.order.price};
        let isChangedPrice: boolean;
        if (orderReshopPriceObj && orderReshopPriceObj.consumer.total) {
          isChangedPrice = orderReshopPriceObj.consumer.total !== priceObj.consumer.total;
          priceObj = orderReshopPriceObj;
        }
        this.latestPrice = {
          isChanged: isChangedPrice,
          value: priceObj.consumer.total,
          currency: priceObj.consumer.currency
        };
      }, res => {
        this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, body, res.status, res);
        this.orderReshopRepriceError = this.helpers.getError(res);
        this.showRepriceIssueLoader = false;
      }, () => {
        this.showRepriceIssueLoader = false;
      });
  }

  onChangeSuccess(response) {
    if (this.fakeAirlines.includes(this.owner)) {
      this.order.price = response.price;
      this.order.flights = response.flights;
    } else {
      window.scrollTo(0, 1000);
      setTimeout(() => {
        this.loadOrder(true, 'OrderChange');
      }, 3000);
    }
    this.closeNotificationModal();
  }

  onSendOrderChangeSuccess(response) {
    this.saveWarnings(response);
    if (this.fakeAirlines.includes(this.owner)) {
      this.order.price = response.price;
      if (response.seats) {
        this.order.seats = response.seats;
      }
      if (response.services) {
        this.order.services = response.services;
      }
    } else {
      window.scrollTo(0, 1000);
      setTimeout(() => {
        this.loadOrder(true, 'OrderChange');
      }, 2000);
    }
  }

  downloadPDF(dropdown: NgbDropdown) {
    this.showOrderPdfLoader = true;
    const body = {id: this.id};
    this.service.sendOrderPDF(body).then((res) => {
      // Download the PDF in the same tab
      window.location.href = res.downloadURL;
      this._notificationSvc.success('SUCCESS', 'PDF file downloaded successfully', 8000);
      this.showOrderPdfLoader = false;
      dropdown.close();
    }).catch(error => {
      this.showOrderPdfLoader = false;
    });
  }

  public generatePDF(pdf) {
    if (this.isAllowPdf) {
      // this.tabset.activeId = this.tabset.tabs.first.id;
      this.selectedTab = this.orderTab.OrderDetails;
      this.isPDFExtract = true;
      this.pdf = pdf;
      this.cdr.detectChanges();
      this.gtmService.addEvent('DownloadLegacyPDFButtonClick');
      this.preparePDFImages();
    }
  }

  preparePDFImages() {
    if (this.authService.agencyImgUrl) {
      const url = this.allOriginsAPI + encodeURIComponent(this.authService.agencyImgUrl);
      fetch(url)
        .then(response => {
          if (response.ok) { return response.json(); }
          setTimeout(() => {
            this.uploadImagesAndSavePDF();
          });
        })
        .then(data => {
          this.agencyImage = data.contents;
          this.uploadImagesAndSavePDF();
        })
        .catch(error => {
          this.uploadImagesAndSavePDF();
        });
    } else {
      this.uploadImagesAndSavePDF();
    }
  }

  uploadImagesAndSavePDF() {
    let that = this;
    let imgs = Array.from(this.winRef.nativeWindow.document.images);
    that.imgLen = imgs.length;
    that.counter = 0;
    imgs.forEach((img: HTMLImageElement) => {
      if (img.complete) {
        that.watchImagesReadyToSavePDF();
      } else {
        img.addEventListener('load', that.watchImagesReadyToSavePDF.bind(that), false);
      }
    });
  }

  watchImagesReadyToSavePDF() {
    this.counter++;
    if (this.counter === this.imgLen) {
      this.pdf.export()
        .then(group => this.pdf.exportGroup(group, this.pdf.pdfOptions()))
        .then(dataUri => this.pdf.saveDataUri(dataUri, `${this.order.id}.pdf`, this.pdf.saveOptions()))
        .then(() => {
          console.log('pdf extract done');
          this.isPDFExtract = false;
        });
    }
  }

  generateFiles(file: string, cardHeaderDropdown: NgbDropdown) {
    let body: any = {
      eventName: '',
      orderID: this.order.id
    };

    let message = 'Your request has been processed';

    if (file === 'air') {
      body.eventName = 'GenerateAIR';
    } else if (file === 'mir') {
      body.eventName = 'GenerateMIR';
    } else if (file === 'galor') {
      body.eventName = 'GenerateGalorPax';
    }

    this.service.createTask(body).then(res => {
      this._notificationSvc.success('', message);
      cardHeaderDropdown.close();
    });
  }

  onTryReload() {
    this.loadOrder(true);
  }

  onBackNavigation() {
    this.router.navigate(['orders']);
  }

  protected selectFareRulesByPsgType(psgType: string) {
    this.selectedFare = this.fareRules[psgType];
    return this.fareRules[psgType];
  }

  checkDefaultFEDependency() {
    const subDomainName = window.location.hostname;
    const environmentSubDomain = environment.frontendDependencies.find(item => item.subDomain === subDomainName);
    if (environmentSubDomain) {
      this.frontendSelectedDependency = environmentSubDomain;
    }
  }

  setViewOpts() {
    const isAgent = this.isAgent();

    this.viewOpts = {
      ...this.viewOpts,
      price: true,
      // price: !this.isTraveler(),
      search: isAgent,
      legacyPdf: isAgent,
      pdf: isAgent,
      files: isAgent,
      close: isAgent,
      orderHistory: isAgent,
      comments: isAgent,
      remarks: isAgent,
      orderLog: isAgent,
      metas: isAgent,
      serviceListButton: isAgent && this.isServiceListAllowed(),
      seatAvailabilityButton: isAgent && this.isSeatAvailabilityAllowed(),
      changeItineraryButton: isAgent && this.isChangeItineraryAllowed(),
      airdocIssueButton: isAgent && this.isAirdocIssueAllowed(),
      repriceButton: isAgent && this.isRepriceAllowed(),
      cancelOrderButton: isAgent && this.isCancelOrderAllowed(),
      actionRequiredButton: isAgent && this.isActionRequired(),
      orderSplitButton: isAgent && this.isOrderSplitAllowed(),
      pendingPaymentsButton: isAgent && this.isPendingPaymentsExist(),
      gds: true,
    };

    this.updateButtonStates();
  }

  updateButtonStates(): void {
    this.buttonStates.selectSeatsButton.isDisabled = (
      (!this.viewOpts.seatAvailabilityButton ||
        this.viewOpts.actionRequiredButton ||
        this.viewOpts.rebookPaymentRequired) &&
      !this.isGodModeEnabled
    );

    this.buttonStates.selectServicesButton.isDisabled = (
      (!this.viewOpts.serviceListButton ||
        this.viewOpts.actionRequiredButton ||
        this.viewOpts.rebookPaymentRequired) &&
      !this.isGodModeEnabled
    );

    this.buttonStates.orderRebookButton.isDisabled = (
      (!this.viewOpts.changeItineraryButton ||
        this.viewOpts.actionRequiredButton ||
        this.viewOpts.rebookPaymentRequired ||
        this.viewOpts.seatsOrServicesPaymentRequired) &&
      !this.isGodModeEnabled
    );

    this.buttonStates.orderSplitButton.isDisabled = (
      (!this.viewOpts.orderSplitButton ||
        this.viewOpts.actionRequiredButton ||
        this.viewOpts.rebookPaymentRequired ||
        this.viewOpts.seatsOrServicesPaymentRequired) &&
      !this.isGodModeEnabled
    );

    this.buttonStates.orderCancelButton.isDisabled = !(
      this.viewOpts.cancelOrderButton && !this.viewOpts.actionRequiredButton
    );
  }

  isAgent() {
    return this.order.viewMode === 'agent';
  }

  toggleSegmentSelection(selectedSegment) {
    if (this.selectedSegmentsMapCopy[selectedSegment]) {
      delete this.selectedSegmentsMapCopy[selectedSegment];
      this.selectedSegmentsCopy = this.selectedSegmentsCopy.filter(segment => {
        return segment !== selectedSegment;
      });
    } else {
      this.selectedSegmentsMapCopy[selectedSegment] = true;
      this.selectedSegmentsCopy.push(selectedSegment);
    }

    let seatsBySegment = [];
    this.selectedSegmentsCopy.map(segment => {
      this.order.seats.filter(seat => {
        if (seat.segment === segment) {
          seatsBySegment.push(seat);
        }
      });
    });
    this.seatsToRemove = seatsBySegment;
  }

  openPreviewSeatRemovalModal(seat, seatRemoveModal: any) {
    this.selectedSegmentsMapCopy = {};
    this.seatsToRemove = this.order.seats.filter(orderSeat => {
      return seat.segment === orderSeat.segment;
    });
    this.selectedSegmentsMapCopy[seat.segment] = true;
    this.selectedSegmentsCopy = [seat.segment];
    this.isRemoveFreeSeats = !seat.price.consumer.total;
    this.open(seatRemoveModal);
  }

  cancelRemovingSeats() {
    this.selectedSegmentsMapCopy = {};
  }

  handleRemoveSeats(c) {
    c('ok');
    if (this.order.status === ORDER_STATUS.PENDING) {
      let segment = '';
      if (this.seatsToRemove.length) {
        segment = this.seatsToRemove[0].segment;
      }
      this.removeSeats(segment);
    } else {
      const freeSeats = this.seatsToRemove.every(seat => seat.price.consumer.total === 0);
      freeSeats ? this.removeSeats() : this.ensurePaymentIsSet(this.removeSeats);
    }
  }

  handleRemoveService(srv: any, segmentId: string = '') {
    this.cbServiceArg = srv;
    this.serviceIdToRemove = segmentId;
    this.isRemoveFreeService = !this.cbServiceArg.price.consumer.total;
    if (this.order.status === ORDER_STATUS.PENDING || this.isRemoveFreeService) {
      this.removeService();
    } else {
      this.ensurePaymentIsSet(this.removeService);
    }
  }

  toggleAncillariesBySegment(odIdx: number, fsIdx: number) {
    if (!this.isAncillariesTableBySegmentHidden[odIdx]) {
      this.isAncillariesTableBySegmentHidden[odIdx] = {};
    }
    this.isAncillariesTableBySegmentHidden[odIdx][fsIdx] = !this.isAncillariesTableBySegmentHidden[odIdx][fsIdx];
  }

  actionOrderConfirm() {
    let body = {
      id: this.id,
    };
    this.orderConfirmProcess.isProcess = true;
    body['action'] = 'accept_disruption';

    this.service.sendOrderChange(body)
      .then((res) => {
        this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, body, 200, res);
        this.saveWarnings(res);
        this.loadOrder(false);
        this._notificationSvc.success('SUCCESS', 'Order is changed', 0);
        this.orderConfirmProcess.isProcess = false;
        this.closeNotificationModal();
      }).catch(res => {
      this.orderConfirmProcess.isProcess = false;
      this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, body, res.status, res);
      if (this.helpers.isCriticalError(res)) {
        throw res;
      }
    });
  }

  getCorporate(corporateID) {
    if (corporateID) {
      this.hubCorporateService.get(corporateID)
        .pipe(
          map((data: any) => data.body),
          map((data: any) => {
            if (data.id === corporateID) {
              return data;
            }
          }),
          take(1))
        .subscribe(res => {
          this.corporate = res;
          this.orderInfo['accountId'] = this.corporate.account_number;
          if (this.corporate.remarks?.length) {
            this.corporateRemarkTemplates = this.corporate.remarks;
          }
        });
    }
  }

  onWarnIconCheck() {
    if (this.order.status === ORDER_STATUS.PENDING || this.order.status === ORDER_STATUS.TICKETED && !this.helpers.isPrivateMode) {
      if (this.ls.orderWarnTabsInfo.length) {
        this.dbService
          .bulkAdd('order_warn_tabs_info', this.ls.orderWarnTabsInfo)
          .subscribe((storeData) => {
          });
        delete localStorage.order_warn_tabs_info;
      } else {
        this.dbService.getAll('order_warn_tabs_info').subscribe((storeData) => {
          this.storeData = storeData;

          if (this.storeData.length === 0) {
            let orderWarnTabsInfo = this.setOrderWarnTabsInfo();

            this.tabsWarnIconVisibility = {
              order_history: orderWarnTabsInfo.order_history,
              comments_content: this.order.comments,
              comments: orderWarnTabsInfo.comments_content,
              metas: !!orderWarnTabsInfo.metas?.items_quantity,
              order_log: !!orderWarnTabsInfo.order_log?.items_quantity
            };

            this.dbService
              .add('order_warn_tabs_info', orderWarnTabsInfo)
              .subscribe((storeData) => {}, error => console.error(error));
          } else {
            this.storeData.map((order, idx) => {
              if (this.order?.orderID === order.orderID) {
                let orderWarnTemp = [...this.storeData];
                let currentOrderTemp = orderWarnTemp.find(order => order.orderID === this.order.orderID);
                let orderWarnTabsInfo = this.setOrderWarnTabsInfo(currentOrderTemp);

                this.tabsWarnIconVisibility = {
                  order_history: currentOrderTemp.order_history,
                  comments: currentOrderTemp.comments,
                  comments_content: currentOrderTemp.comments_content,
                  metas: !!this.order.metas && currentOrderTemp.metas?.items_quantity !== this.order.metas?.length,
                  order_log: !!this.order.logs && currentOrderTemp.order_log?.items_quantity !== this.order.logs?.length
                };

                if (this.order.comments !== this.tabsWarnIconVisibility.comments_content) {
                  this.tabsWarnIconVisibility.comments = true;
                }
                let idx = orderWarnTemp.findIndex(order => order.orderID === this.order.orderID);
                orderWarnTabsInfo['id'] = order.id;
                this.dbService
                  .update('order_warn_tabs_info', orderWarnTabsInfo)
                  .subscribe((storeData) => {}, error => console.error(error));
              } else if (this.order && this.order?.orderID !== order.orderID && this.storeData.findIndex(order => order.orderID === this.order?.orderID) === -1) {
                let orderWarnTabsInfo = this.setOrderWarnTabsInfo();
                this.tabsWarnIconVisibility = {
                  order_history: orderWarnTabsInfo.order_history,
                  comments: orderWarnTabsInfo.comments,
                  comments_content: orderWarnTabsInfo.comments_content,
                  metas: !!orderWarnTabsInfo.metas?.items_quantity,
                  order_log: !!orderWarnTabsInfo.order_log?.items_quantity
                };

                let orderWarnTemp = [...this.storeData];
                orderWarnTemp.push(orderWarnTabsInfo);
                if (idx) {
                  this.dbService
                    .bulkAdd('order_warn_tabs_info', orderWarnTemp)
                    .subscribe((storeData) => {}, error => console.error(error));
                } else {
                  this.dbService
                    .add('order_warn_tabs_info', orderWarnTabsInfo)
                    .subscribe((storeData) => {}, error => console.error(error));
                }
              }
            });
          }
        }, error => console.error(error));
      }
    }
  }

  onWarnIconChange(item, status) {
    if (status === 'Pending' || status === 'Ticketed' && !this.helpers.isPrivateMode) {
      this.dbService.getAll('order_warn_tabs_info').subscribe((storeData) => {
        this.storeData = storeData;
        let tempWarnTabsInfos = [...this.storeData];
        let idx = tempWarnTabsInfos.findIndex(order => order.orderID === this.order?.orderID);
        if (tempWarnTabsInfos[idx]) {
          if (item === 'order_log' || item === 'metas') {
            tempWarnTabsInfos[idx][item].unchecked = false;
          } else {
            tempWarnTabsInfos[idx][item] = false;
          }
          this.tabsWarnIconVisibility[item] = false;

          tempWarnTabsInfos[idx].comments_content = this.order.comments;

          this.dbService
            .update('order_warn_tabs_info', tempWarnTabsInfos[idx])
            .subscribe((storeData) => {}, error => console.error(error));
        }
      }, error => console.error(error));
    }
  }

  private addCarbonOffsetFlightKeys(flights, carbonOffset) {
    let res = [];
    carbonOffset.map(item => {
      let flightKey = '';
      if (item.segmentKey) {
        flights.map(f => {
          f?.segments?.map(fs => {
            if (fs.segmentID === item.segmentKey) {
              flightKey = f.key;
            }
          });
        });
      }
      res.push({
        flightKey,
        ...item,
      });
    });
    return res;
  }

  orderCarbonOffsetFilterByKey(key) {
    return this.order.carbonOffset.filter(c => c.flightKey === key);
  }

  setOrderWarnTabsInfo(order?) {
    return {
      orderID: this.order.orderID,
      last_arrival: this.order.flights[this.order.flights.length - 1].arrival.date,
      order_history: order ? order.order_history : true,
      comments: order ? order.comments : true,
      comments_content: order ? order.comments_content : this.order.comments,
      metas: {
        unchecked: order ? order.metas.unchecked : true,
        items_quantity: this.order.metas ? this.order.metas?.length : 0
      },
      order_log: {
        unchecked: order ? order.order_log.unchecked : true,
        items_quantity: this.order.logs ? this.order.logs?.length : 0
      }
    };
  }

  setOrderInfo() {
    this.orderInfo['origin'] = this.order.flights[0].departure.airportCode;
    this.orderInfo['destination'] = this.order.flights[0].arrival.airportCode;
    this.orderInfo['id'] = this.order.id;
    this.orderInfo['status'] = this.order.status;
    this.orderInfo['psgInfo'] = this.order.price.consumer;
    this.orderInfo['passengers'] = this.order.passengers;

    if (this.order.tickets?.length) {
      const ticketNumber = this.order.tickets[0].ticketNumber;
      this.orderInfo['ticketNumber'] = ticketNumber;
      this.orderInfo['ticketSerialNumber'] = ticketNumber.slice(5);
      this.orderInfo['airlineCodeTicket'] = ticketNumber.slice(0, 5);
    }
  }

  openSabrePassiveSegmentModal(sabrePassiveSegmentModal) {
    this.open(sabrePassiveSegmentModal);
    this.passiveSegmentService.order = this.order;
    this.anonymizedSabrePassiveSegment = this.passiveSegmentService.generate('sabre', true);
    this.clearPassiveSegment();
    this.originalSabrePassiveSegment = this.passiveSegmentService.generate('sabre');
  }

  openAmadeusPassiveSegmentModal(amadeusPassiveSegmentModal) {
    this.open(amadeusPassiveSegmentModal);
    this.passiveSegmentService.order = this.order;
    this.anonymizedAmadeusPassiveSegment = this.passiveSegmentService.generate('amadeus', true);
    this.clearPassiveSegment();
    this.originalAmadeusPassiveSegment = this.passiveSegmentService.generate('amadeus');
  }

  openTravelportPassiveSegmentModal(travelportPassiveSegmentModal) {
    this.open(travelportPassiveSegmentModal);
    this.passiveSegmentService.order = this.order;
    this.travelportPassiveSegment = this.passiveSegmentService.generate('travelport');
  }

  clearPassiveSegment() {
    this.passiveSegmentService.flights = [];
    this.passiveSegmentService.passiveSegments = [];
  }

  redirectToNewUrl() {
    const owner = this.route.snapshot.paramMap.get('owner');
    if (owner) {
      this.router.navigateByUrl(`orders/${this.id}`);
    }
  }

  saveWarnings(response) {
    if (response?.warnings && !this.ls.warningsDisabled) {
      let warningsInfo = {
        id: response.id,
        warnings: response.warnings
      };
      this.ls.orderWarnings = JSON.stringify(warningsInfo);
    }
  }

  removeEmptyTitleFromPassengers(passengers) {
    if (passengers) {
      Object.values(passengers).map((passenger: any) => {
        if (!passenger.data.title) {
          delete passenger.data.title;
        }
      });
    }
  }

  selectPassenger(passenger: any) {
    this.selectedPassenger = passenger;
  }

  cancelPassengerEditing() {
    this.passengerForm.reset(this.passengerFormData);
  }

  buildPassengerForm() {
    this.passengerForm = this.fb.group({
      loyaltyProgramAccount: [
        {
          value: this.order.loyaltyProgramAccount || '',
          disabled: this.shouldDisablePassengerControl(this.order.passengers[0], 'fqtv_ob_adding_correction', 'loyaltyProgramAccount')
        }
      ],
    });
  }

  validatePassengerForm() {
    this.validateTriggered = true;
    return this.passengerForm.valid;
  }

  findAllowedUpdatesPerPassengerType() {
    this.allowedUpdatesPerPassengerType = {};
    this.availablePassengersToUpdate.forEach((passenger) => {
      const psgType = passenger.passengerType;
      for (const update in this.allowedPassengerUpdates) {
        if (this.allowedPassengerUpdates[update]?.allowed && this.allowedPassengerUpdates[update].allowedPerPassengerType?.[psgType]) {
          if (!this.allowedUpdatesPerPassengerType[psgType]) {
            this.allowedUpdatesPerPassengerType[psgType] = {};
          }
          this.allowedUpdatesPerPassengerType[psgType].updatesAllowed = true;
          this.allowedUpdatesPerPassengerType[psgType][update] = true;
        }
      }
    });
  }

  updatePassengerInfo(action?: string) {
    this.currentOrderUpdateAction = action;

    if (!this.validatePassengerForm()) {
      return;
    }
    this.orderUpdateProcess.isProcess = true;

    const passengerDataToUpdate = this.getPassengerDataToUpdate(this.passengerDataToUpdate);

    const body: any = {
      action: action,
      id: this.order.id,
      passengerUpdate: passengerDataToUpdate
    };

    if (action === 'fqtv_ob_adding_correction' && this.passengerForm.get('loyaltyProgramAccount').value) {
      body.loyaltyProgramAccount = this.passengerForm.get('loyaltyProgramAccount').value;
    }

    this.service.orderUpdate(body)
      .then(res => {
        this.saveWarnings(res);
        this.orderUpdateProcess.isProcess = false;
        this.modalService.dismissAll();
        this.loadOrder(true);
        this.successSave();
        this.validateTriggered = false;
        this.passengersDropdownDisabled = false;
      })
      .catch((res) => {
        this.orderUpdateProcess.isProcess = false;
      });
  }

  getPassengersForUpdate() {
    this.passengerDataToUpdate = {
      data: {
        name: this.availablePassengersToUpdate[0]?.data.name,
        birthdate: null
      },
      travelerReference: this.availablePassengersToUpdate[0]?.travelerReference,
      documents: [],
      documentCorrection: {
        new: [],
        delete: []
      }
    };
    this.availablePassengersToUpdate.map((passenger, idx) => {
      const psgKey = 'passenger_' + idx;
      let initialPassengerForm = this.passengerForm.get(psgKey);
      let initialPassengerFormValue = initialPassengerForm.value;
      Object.keys(initialPassengerFormValue).map(key => {
        let initialPassengerFormValueByKey = this.passengerForm.get(psgKey).get(key).value;
        this.passengerForm.get(psgKey).get(key).valueChanges
          .pipe(takeUntil(this.ngUnsubscribe$))
          .subscribe(data => {
            this.passengersDropdownDisabled = JSON.stringify(data) !== JSON.stringify(initialPassengerFormValueByKey);
            this.passengerDataToUpdate.data.name = passenger.data.name;
            if (!this.passengerDataToUpdate.data.birthdate) {
              if (passenger.data.birthdate) {
                this.passengerDataToUpdate.data.birthdate = this.helpers.formatDateToNgbDateStruct(passenger.data.birthdate);
              }
            }
            this.passengerDataToUpdate.travelerReference = passenger.travelerReference;
            for (const [jkey, jvalue] of Object.entries(data)) {
              if (key === 'documents') {
                this.passengerDataToUpdate.documents = this.passengerForm.get(psgKey).get('documents').value;
              } else {
                if ((jvalue !== passenger[key][jkey]) || (this.passengerDataToUpdate[key][jkey] && jvalue !== this.passengerDataToUpdate[key][jkey])) {
                  this.passengerDataToUpdate[key][jkey] = jvalue;
                }
              }
            }
        });
      });
    });
  }

  getPassengerDataToUpdate(passengerDataToUpdate) {
    let clonedPassengerData = JSON.parse(JSON.stringify(passengerDataToUpdate));

    if (clonedPassengerData.data.birthdate) {
      clonedPassengerData.data.birthdate = HelpersService.getFormattedDate(clonedPassengerData.data.birthdate);
    }

    if (clonedPassengerData.data.hasOwnProperty('addressType')) {
      delete clonedPassengerData.data.addressType;
    }

    if (clonedPassengerData.data.address?.label) {
      let hasValues = Object.values(clonedPassengerData.data.address).some(value => value && value !== "addressAtHome");
      if (!hasValues) {
        clonedPassengerData.data.address.label = '';
      }
    }

    if (clonedPassengerData.data.phone) {
      clonedPassengerData.data.phone = convertPhoneIntoString(clonedPassengerData.data.phone);
    }

    if (clonedPassengerData.documents.length) {
      clonedPassengerData.documents.map(doc => {
        doc.expirationDate = HelpersService.getFormattedDate(doc.expirationDate);
        if (doc.token) {
          clonedPassengerData.documentCorrection.delete.push(doc.token);
        }
        delete doc.token;
        clonedPassengerData.documentCorrection.new.push(doc);
      });
    }

    delete clonedPassengerData.documents;

    return clonedPassengerData;
  }

  addControlForEachPassenger() {
    this.availablePassengersToUpdate.map((passenger, idx) => {
      const passengerControlName = `passenger_${idx}`;
      const control = this.makePassengerGroup(passenger, idx);
      this.passengerForm.addControl(passengerControlName, control);
    });
  }

  shouldDisablePassengerControl(passenger: any, correctionType: string, fieldName: string): boolean {
    return !this.allowedUpdatesPerPassengerType[passenger?.passengerType]?.[correctionType] || !this.allowedPassengerUpdates[correctionType]?.fieldsToUpdate.includes(fieldName);
  }

  makePassengerGroup(passenger, index: number) {
    const emailValidators = this.allowedPassengerUpdates.pax_info_correction?.fieldsToUpdate.includes('Email')
                              ? [Validators.pattern(this.paymentService.emailPattern)]
                                : [];
    const phoneNumber = this.parsePhoneNumber(passenger.data.phone);
    const phoneNumberValidators = this.allowedPassengerUpdates.pax_info_correction?.fieldsToUpdate.includes('Phone') &&
                                    phoneNumber?.country ? [Validators.required, Validators.pattern(this.paymentService.digitsPattern)]
                                      : [];

    let form: FormGroup = this.fb.group({
      data: this.fb.group({
        name: [{ value: passenger.data.name || '', disabled: this.shouldDisablePassengerControl(passenger, 'pax_info_correction', 'Name') }],
        surname: [{ value: passenger.data.surname || '', disabled: this.shouldDisablePassengerControl(passenger, 'pax_info_correction', 'Surname') }],
        birthdate: [{ value: passenger.data.birthdate ? HelpersService.getStructFromDate(new Date(passenger.data.birthdate)) : null,
          disabled: this.shouldDisablePassengerControl(passenger, 'birthdate_correction', 'birthdate') }],
        title: [{ value: passenger.data.title || '', disabled: this.shouldDisablePassengerControl(passenger, 'pax_info_correction', 'Title') }],
        gender: [{ value: passenger.data.gender || '', disabled: this.shouldDisablePassengerControl(passenger, 'pax_info_correction', 'Gender') }],
        email: [{ value: passenger.data.email?.toLowerCase() || '', disabled: this.shouldDisablePassengerControl(passenger, 'pax_info_correction', 'Email') }, emailValidators],
        phone: this.fb.group({
          countryCode: [phoneNumber?.countryCallingCode || '34'],
          number: [{ value: phoneNumber?.nationalNumber || '', disabled: this.shouldDisablePassengerControl(passenger, 'pax_info_correction', 'Phone') }, phoneNumberValidators]
        }),
        fqtvInfo: this.buildFqtvInfo(passenger),
        address: this.buildAddress(passenger.data.address, passenger, 'addressAtHome'),
        addresses: this.fb.array(this.setExistingAddresses(passenger)),
        addressType: ['']
      }),
      documents: this.fb.array(this.setExistingDocuments(passenger))
    });
    const startedName = this.availablePassengersToUpdate[index].data.name;
    const startedSurname = this.availablePassengersToUpdate[index].data.surname;
    const startedTitle = this.availablePassengersToUpdate[index].data.title;
    let name = form.get('data.name');
    let surname = form.get('data.surname');
    let title = form.get('data.title');
    name.valueChanges.subscribe(value => {
      if (value) {
        name.setValidators([Validators.pattern(this.namePattern), PassengerUpdatesValidator.NameCorrectionValidator(startedName, this.allowedPassengerUpdates.pax_info_correction.maxCharsAllowed)]);
      } else {
        name.setValidators([Validators.required]);
      }
      name.updateValueAndValidity({emitEvent: false, onlySelf: false});
    });
    surname.valueChanges.subscribe(value => {
      if (value) {
        surname.setValidators([Validators.pattern(this.namePattern), PassengerUpdatesValidator.NameCorrectionValidator(startedSurname, this.allowedPassengerUpdates.pax_info_correction.maxCharsAllowed)]);
      } else {
        surname.setValidators([Validators.required]);
      }
      surname.updateValueAndValidity({emitEvent: false, onlySelf: false});
    });

    title.valueChanges.subscribe(value => {
      if (value) {
        title.setValidators([PassengerUpdatesValidator.NameCorrectionValidator(startedTitle, this.allowedPassengerUpdates.pax_info_correction.maxCharsAllowed)]);
      } else {
        title.setValidators([Validators.required]);
      }
      title.updateValueAndValidity({emitEvent: false, onlySelf: false});
    });

    let airlineId  = form.get('data.fqtvInfo.airlineID');
    let frequentFlyerNumber = form.get('data.fqtvInfo.account.number');

    airlineId.valueChanges.subscribe((value: string) => {
      if (value) {
        frequentFlyerNumber.setValidators([Validators.required]);
      } else {
        frequentFlyerNumber.clearValidators();
      }
      frequentFlyerNumber.updateValueAndValidity({emitEvent: false, onlySelf: false});
    });

    frequentFlyerNumber.valueChanges.subscribe((value: string) => {
      if (value) {
        airlineId.setValidators([Validators.required]);
      } else {
        airlineId.clearValidators();
      }
      airlineId.updateValueAndValidity({emitEvent: false, onlySelf: false});
    });

    return form;
  }

  parsePhoneNumber(phone: string): any {
    try {
      return phone ? parsePhoneNumber('+' + phone) : null;
    } catch (err) {
      return null;
    }
  }

  onCountryChange(psgIdx: any, $event: any) {
    this.setPhoneCountryCodeToPassengerForm(psgIdx, $event.dialCode);
    this.helpers.saveTelCountryNumber($event);
  }

  telInputObject(psgIdx, $event: any) {
    this.telInputObjects[psgIdx] = $event;
    let selectedCountry = this.passengerForm.controls[psgIdx].get('data').get('phone').get('countryCode').value;
    if (!selectedCountry) {
      let savedTelCountry = this.ls.settings?.telCountryNumber;
      Promise.resolve().then(() => this.setPhoneCountryCodeToPassengerForm(psgIdx, savedTelCountry?.dialCode || $event.s.dialCode));
    }
    this.helpers.onTelInputObject(this.telInputObjects[psgIdx], selectedCountry);
  }

  setPhoneCountryCodeToPassengerForm(psgIdx: string, value: string) {
    this.passengerForm.controls[psgIdx].get('data').get('phone').get('countryCode').setValue(value);
  }

  setExistingDocuments(passenger) {
    if (passenger.documents) {
      const controls = [];
      passenger.documents.forEach(document => {
        let control = this.buildDocument(document, passenger);
        controls.push(control);
      });
      return controls;
    } else {
      return [];
    }
  }

  addDocument(psgKey: string, documentType?: string, passenger?: any) {
    const document: any = {};
    if (documentType) {
      document.documentType = documentType;
    }
    const control = this.buildDocument(document, passenger);
    (this.passengerForm.get(psgKey).get('documents') as FormArray).push(control);
  }

  removeDocument(controlName: string, index: number) {
    const arrayControl = this.passengerForm.get(controlName) as FormArray;
    arrayControl.markAsDirty();
    const token = arrayControl.at(index).get('token').value;
    arrayControl.removeAt(index);
    if (token) {
      this.passengerDataToUpdate.documentCorrection.delete.push(token);
    }
  }

  buildAddress(address: any = {}, passenger: any = {}, label: 'addressAtHome' | 'addressAtDestination') {
    if (label === 'addressAtHome') {
      this.addresses.home = true;
    } else {
      this.addresses.destination = true;
    }
    return this.fb.group({
      'label': [
        { value: label || '', disabled: this.shouldDisablePassengerControl(passenger, 'address_correction', 'label') }
      ],
      'countryCode': [
        { value: address.countryCode || '', disabled: this.shouldDisablePassengerControl(passenger, 'address_correction', 'countryCode') }
      ],
      'cityName': [
        { value: address.cityName || '', disabled: this.shouldDisablePassengerControl(passenger, 'address_correction', 'cityName') }
      ],
      'postalCode': [
        { value: address.postalCode || '', disabled: this.shouldDisablePassengerControl(passenger, 'address_correction', 'postalCode') }
      ],
      'street': [
        { value: address.street || '', disabled: this.shouldDisablePassengerControl(passenger, 'address_correction', 'street') }
      ]
    });
  }

  setExistingAddresses(passenger: any) {
    if (passenger.data.addresses) {
      const controls = [];
      passenger.data.addresses.forEach((address) => {
        let control = this.buildAddress(address, passenger, 'addressAtDestination');
        controls.push(control);
      });
      return controls;
    } else {
      return [];
    }
  }

  addAddress(address: string, passenger: any = {}, psgKey: string) {
    this.addresses[address] = true;
    if (address === 'destination') {
      const control = this.buildAddress({}, passenger, 'addressAtDestination');
      (this.passengerForm.get(psgKey).get('data.addresses') as FormArray).push(control);
    }
    this.passengerForm.get(psgKey).get('data.addressType').setValue('');
  }

  removeAddress(address: string, controlName: string, index = 0) {
    const control = this.passengerForm.get(controlName);
    if (address === 'destination') {
      if (!control.value?.length) {
        this.addresses[address] = false;
      }
      (control as FormArray).removeAt(index);
    } else {
      this.addresses[address] = false;
      control.reset({
        countryCode: '',
        label: 'addressAtHome'
      });
    }
  }

  buildFqtvInfo(passenger: any) {
    const validators = passenger.data.fqtvInfo?.airlineID || passenger.data.fqtvInfo?.account?.number ? [Validators.required] : [];
    return this.fb.group({
      airlineID: [
        { value: passenger.data.fqtvInfo?.airlineID || '',
          disabled: this.shouldDisablePassengerControl(passenger, 'fqtv_ob_adding_correction', 'fqtvInfo') },
        validators
      ],
      account: this.fb.group({
        number: [
          { value: passenger.data.fqtvInfo?.account?.number || '',
            disabled: this.shouldDisablePassengerControl(passenger, 'fqtv_ob_adding_correction', 'fqtvInfo') },
          validators
        ]
      }),
    });
  }

  buildDocument(document: any = {}, passenger: any = {}) {
    const documentIdValidators = document.documentID ? [Validators.required] : [];
    const documentTypeValidators = document.documentType ? [Validators.required] : [];
    return this.fb.group({
      'documentID': [
        { value: document.documentID || '',
          disabled: this.shouldDisablePassengerControl(passenger, 'document_correction', 'documentID') },
        documentIdValidators
      ],
      'documentType': [
        { value: document.documentType === 'PT' ? 'PP' : document.documentType || '',
          disabled: this.shouldDisablePassengerControl(passenger, 'document_correction', 'documentType') },
        documentTypeValidators
      ],
      'expirationDate': [
        { value: document.expirationDate ? HelpersService.getStructFromDate(new Date(document.expirationDate)) : null,
          disabled: this.shouldDisablePassengerControl(passenger, 'document_correction', 'expirationDate') }
      ],
      'fiscalName': [
        { value: document.fiscalName || '',
          disabled: this.shouldDisablePassengerControl(passenger, 'document_correction', 'fiscalName') }
      ],
      'issuingCountryCode': [
        { value: document.issuingCountryCode || '',
          disabled: this.shouldDisablePassengerControl(passenger, 'document_correction', 'issuingCountryCode') }
      ],
      'citizenshipCountryCode': [
        { value: document.citizenshipCountryCode || '',
          disabled: this.shouldDisablePassengerControl(passenger, 'document_correction', 'citizenshipCountryCode') }
      ],
      'residenceCountryCode': [
        { value: document.residenceCountryCode || '',
          disabled: this.shouldDisablePassengerControl(passenger, 'document_correction', 'residenceCountryCode') }
      ],
      'token': [
        { value: document.token || '',
          disabled: false }
      ]
    });
  }

  selectBirthdate($element: HTMLElement) {
    if ($element) {
      setTimeout(() => {
        $element.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center'
        });
      });
    }
  }

  onBirthdateChanged(dateString: string, psgKey: string, travelerType: string) {
    this.dateService.updateDateControl(
      dateString,
      this.passengerForm.get(psgKey + '.data'),
      'birthdate',
      this.minMaxDateOfBirth[travelerType.toUpperCase()]?.minDate,
      this.minMaxDateOfBirth[travelerType.toUpperCase()]?.maxDate
    );
  }

  onExpirationDateChanged(dateString: string, control: FormGroup) {
    this.dateService.updateDateControl(
      dateString,
      control,
      'expirationDate',
      this.minMaxDocumentExpirationDate?.minDate
    );
  }

  successSave() {
    this._notificationSvc.clearNotifications();
    this._notificationSvc.success('SUCCESS', 'Passenger data has been successfully updated', 8000);
  }

  setInfantReferenceFullNameForDesignatedAdults() {
    this.order.passengers.map(passenger => {
      if (passenger.infantReference) {
        const infantDetail = this.order.passengers.find(p => p.travelerReference === passenger.infantReference);
        if (infantDetail) {
          passenger.infantReferenceFullName = infantDetail.data.name + ' ' + infantDetail.data.surname;
        }
      }
    });
  }

  toggleReadWarnings() {
    this.allWarningsAreShown = !this.allWarningsAreShown;
  }

  toggleDisplayWarnings() {
    this.showWarnings = !this.showWarnings;
  }

  getWarningsHeight() {
    setTimeout(() => {
      if (this.warnings) {
        this.allWarningsAreShown = this.warnings.nativeElement.offsetHeight < 190;
        this.isWarningsReadBtnNeeded = !this.allWarningsAreShown;
      }
    }, 100);
  }

  onOpenTab() {
    // this.tabset.activeId = this.orderTab.OrderHistory;
    this.selectedTab = this.orderTab.OrderHistory;
  }

  selectTab(tab: OrderTab) {
    if (this.isRemarksTemplateSelected && !this.ls.isInfoModalSkipped) {
      this.confirmDialogService.confirmDialog().then(res => {
        if (res) {
          this.selectedTab = tab;
          this.isRemarksTemplateSelected = false;
          this.updateViewAndCheckScroll();
        }
      });
    } else {
      this.selectedTab = tab;
      this.updateViewAndCheckScroll();
    }
  }

  updateIssuingPendings() {
    if (this.order.issuingPendings?.length) {
      let rebookObj = null;
      const updatedIssuingPendings = [];

      this.order.issuingPendings.forEach((item) => {
        if (item.type === 'rebook' && item.reference) {
          this.viewOpts.rebookPaymentRequired = true;
          if (!rebookObj) {
            rebookObj = {
              price: {
                consumer: {
                  base: 0,
                  currency: "EUR",
                  fee: { total: 0 },
                  surcharge: 0,
                  tax: { total: 0 },
                  total: 0
                }
              },
              priceByPassengerType: {},
              type: 'rebook'
            };
          }
          rebookObj.price.consumer.total += item.price.consumer.total;
          rebookObj.price.consumer.currency = item.price.consumer.currency;
          const references = item.reference.split(' ');
          const passenger = this.order.passengers.find(pass => references.includes(pass.travelerReference));
          if (passenger) {
            if (!rebookObj.priceByPassengerType[passenger.passengerType]) {
              rebookObj.priceByPassengerType[passenger.passengerType] = {};
            }
            rebookObj.priceByPassengerType[passenger.passengerType].price = item.price;
            rebookObj.priceByPassengerType[passenger.passengerType].count = references.length;
          }
        } else {
          this.viewOpts.seatsOrServicesPaymentRequired = true;
          updatedIssuingPendings.push(item);
        }
      });

      if (rebookObj) {
        updatedIssuingPendings.unshift(rebookObj);
      }
      this.order.issuingPendings = updatedIssuingPendings;
    }
  }

  updateViewAndCheckScroll(): void {
    this.cdr.detectChanges();
    this.determineScrollPosition();
  }

  determineScrollPosition(): void {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    this.isAtBottom = (windowHeight + scrollTop) >= (documentHeight - 1);
  }

  isPendingPaymentsWarningNeeded() {
    const paymentForm = this.paymentService?.form;
    const paymentMethod = paymentForm?.get('method')?.value;

    return paymentForm && paymentMethod !== 'none' && this.isPendingPaymentsExist();
  }

  updateAllowedPaymentMethods() {
    if (this.owner === 'AA' && this.order.seats?.length) {
      this.order.allowedPaymentMethods.agencyCash = false;
    }
  }

  proceedPayment(c: any) {
    if (!this.validate()) {
      return false;
    }
    if (this.paymentStep === 0 && !this.seatsToRemove?.length && !this.cbServiceArg) {
      this.paymentStep = 1;
    } else {
      this.savePayment();
      c('ok');
    }
  }

  cancelPayment() {
    this.paymentStep = 0;
    this.loadOrder(true);
  }

  checkGodMode() {
    this.helpers.getGodModeData().subscribe((godMode) => {
      this.isGodModeEnabled = godMode.isEnabled;
      if (godMode.isTriggered) {
        this.updateActions();
      }
    });
  }

  updateActions() {
    if (!this.isFirstStateSaved) {
      this.isFirstStateSaved = true;

      // save first state of all actions
      this.orderViewActions.paymentSettings = Object.assign({}, this.paymentSettings);
      this.orderViewActions.seatAvailabilityButton = this.viewOpts.seatAvailabilityButton;
      this.orderViewActions.serviceListButton = this.viewOpts.serviceListButton;
      this.orderViewActions.airdocIssueButton = this.viewOpts.airdocIssueButton;
      this.orderViewActions.changeItineraryButton = this.viewOpts.changeItineraryButton;
      this.orderViewActions.cancelOrderButton = this.viewOpts.cancelOrderButton;
      this.orderViewActions.orderSplitButton = this.viewOpts.orderSplitButton;
    }

    if (this.isGodModeEnabled) {
      // enable access to all possible actions
      Object.keys(this.paymentSettings).map(key => {
        this.paymentSettings[key] = true;
      }); // enable all allowed payment methods
      this.viewOpts.seatAvailabilityButton = true; // select seats button
      this.viewOpts.serviceListButton = true; // select services button
      this.viewOpts.airdocIssueButton = true; // order issue button
      this.viewOpts.changeItineraryButton = true; // set payment details button
      this.viewOpts.cancelOrderButton = true; // order cancel button
      this.viewOpts.orderSplitButton = true; // order split button
    } else {
      // return all actions to their original state
      this.paymentSettings = Object.assign({}, this.orderViewActions.paymentSettings);
      this.viewOpts.seatAvailabilityButton = this.orderViewActions.seatAvailabilityButton;
      this.viewOpts.serviceListButton = this.orderViewActions.serviceListButton;
      this.viewOpts.airdocIssueButton = this.orderViewActions.airdocIssueButton;
      this.viewOpts.changeItineraryButton = this.orderViewActions.changeItineraryButton;
      this.viewOpts.cancelOrderButton = this.orderViewActions.cancelOrderButton;
      this.viewOpts.orderSplitButton = this.orderViewActions.orderSplitButton;
    }
  }

  get isActiveOrder(): boolean {
    return (
      this.isPendingOrder() ||
      this.isTicketedOrder() ||
      this.isStartedOrder() ||
      this.isIssuedOrder()
    );
  }

  generateTracerLink(requestID: string): string {
    return `https://tracer.airgateway${environment.domain}/search?q=${requestID}`;
  }

}
