import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {rotateAnimation} from "angular-animations";
import {NDCApiService} from "../../../shared/services/ndc-api.service";
import {AirlineNotification} from "../../../shared/interfaces/airline-notification";

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
  animations: [
    rotateAnimation({ degrees: -180, duration: 500 }),
  ]
})
export class HistoryComponent implements OnInit {
  @Input() order: any;
  @Input() passengers = [];
  @Output() emitNotificationChange = new EventEmitter();

  isDefaultSort = true;

  readonly allowedActionsForItineraryDisplay = ['OrderCreated', 'OrderTicketed'];

  constructor(private ndcApiService: NDCApiService) { }


  ngOnInit() {}

  getDescriptionDetails(description) {
    let descriptionDetails: {order: string, id: string, link: string} = {
      order: '',
      id: '',
      link: ''
    };
    const splitedDescription = description.split(': ');
    descriptionDetails.order = splitedDescription[0];
    descriptionDetails.id = splitedDescription[1];
    descriptionDetails.link = 'orders/' + this.order.bookingReference.airlineID + '/' + splitedDescription[1];

    return descriptionDetails;
  }

  hasExchangedTickets(tickets: any[]): boolean {
    return tickets?.some(ticket => ticket.ticketExchangedNumber);
  }

  openInNewTab(url: string) {
    window.open(url, "_blank");
  }

  toggleSort() {
    this.isDefaultSort = !this.isDefaultSort;
    this.order.history = this.order.history.slice().reverse();
  }

  onNotificationChange(airlineNotification: AirlineNotification) {
    const body = {
      id: this.order.id,
      notificationIds: [airlineNotification.id]
    };
    this.ndcApiService.orderNotificationSeenToggle(body).then((res) => {
      this.emitNotificationChange.emit(res);
    }).catch((err) => {
      console.error(err);
    });
  }
}
