import {Injectable} from '@angular/core';
import {faker} from '@faker-js/faker';
import {FullMinMaxAgeRange, ProviderAgesScheme} from '../interfaces/date';
import {DateService} from './date.service';
import esFaker from '@faker-js/faker/locale/es';
import {AVAILABLE_TITLES, GENDERS} from "../constants";
import {Dictionary} from "../types/dictionary";


@Injectable({
  providedIn: 'root'
})
export class FakerService {
  genders = GENDERS;
  titles = AVAILABLE_TITLES;

  constructor(private dateService: DateService) {
  }

  getRandomPassenger(psgType, offer): any {
    let psg = {
      documents: [{
        documentID: faker.datatype.number({min: 100000000, max: 999999999}).toString() + 'A',
        documentType: offer.owner === 'VY' ? 'DNI' : 'PP',
        expirationDate: this.getRandomDate(0),
        issuingCountryCode: this.getRandomCountryCode(),
        citizenshipCountryCode: this.getRandomCountryCode(),
        residenceCountryCode: this.getRandomCountryCode()
      }],
      data: {
        birthdate: this.getBirthdate(psgType.toUpperCase(), offer),
        title: this.getRandomValueFromArray(this.titles),
        gender: this.getRandomValueFromArray(this.genders),
        name: this.getName(),
        surname: this.getSurName(),
        email: 'smith@airgateway.net',
        fqtvInfo: {
          account: {
            number: ''
          },
          airlineID: ''
        }
      }
    };
    return psg;
  }

  getSurName(): string {
    const surname = faker.name.lastName();
    return surname.replace(/[^a-zA-Z ]/g, "");
  }

  getName(): string {
    const name = faker.name.firstName();
    return name.replace(/[^a-zA-Z ]/g, "");
  }

  getRandomContactDetails(psg) {
    return {
      data: {
        email: `${psg.data.name}-${psg.data.surname}@airgateway.net`.toLowerCase(),
        phone: {
          countryCode: '34',
          number: esFaker.phone.phoneNumberFormat(3).replace(/-/ig, '')
        },
        address: {
          countryCode: 'ES',
          cityName: faker.address.cityName(),
          postalCode: faker.address.zipCode(),
          street: faker.address.streetName(),
        }
      }
    };
  }

  getBirthdate(psgType, offer) {
    const provider = ProviderAgesScheme[offer.owner] || ProviderAgesScheme.default;
    const minMaxYears = provider[psgType] || FullMinMaxAgeRange;
    let range = this.dateService.getMinMaxDate(true, '', ...minMaxYears);
    let minDate = range.minDate;
    let maxDate = range.maxDate;
    let randomDate = faker.date.between(`${psgType === 'ADT' ? 1950 : minDate.year}-${minDate.month.toString().padStart(2, '0')}-${minDate.day}`, `${maxDate.year}-${maxDate.month.toString().padStart(2, '0')}-${maxDate.day}`);

    return {
      year: randomDate.getFullYear(),
      month: randomDate.getMonth() + 1,
      day: randomDate.getDate(),
    };
  }

  getRandomDate(year) {
    let currentYear = new Date().getFullYear();
    let maxYears = 4;
    let maxMonth = 11;
    let maxDay = 28; // 28 avoids issues if the month has 28/29, 30 or 31 days
    if (!year || year === 0) {
      year = Math.floor((Math.random() * maxYears) + 1);
    }
    year = currentYear + year;

    return {
      year: year,
      month: Math.floor((Math.random() * maxMonth) + 1),
      day: Math.floor((Math.random() * maxDay) + 1),
    };
  }

  getRandomValueFromArray(array: any[]): string {
    let random = Math.floor(Math.random() * array.length);
    return array[random];
  }

  getRandomCountryCode(): string {
    const keys = Object.keys(Dictionary.Countries);
    return keys[Math.floor(Math.random() * keys.length)];
  }

}
